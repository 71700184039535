<template>
<div>
  <router-view></router-view>
</div>
</template>

<script>
export default {

}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}

  .box{
    width: 300px;
    height: 300px;
    background-color: pink;
  }
</style>
