const INFO_KEY = 'shopping_Userinfo'
const HISTORY_KEY = 'search_history'
const CART_KEY = 'cart_goodsnum'
export const getInfo = () => {
  const defaultObj = { token: '', userId: '' }
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

export const setInfo = (info) => {
  return localStorage.setItem(INFO_KEY, JSON.stringify(info))
}

export const removeInfo = () => {
  return localStorage.removeItem(INFO_KEY)
}

export const getHistory = () => {
  const result = localStorage.getItem(HISTORY_KEY)
  return result ? JSON.parse(result) : []
}

export const setHistory = (arr) => {
  return localStorage.setItem(HISTORY_KEY, JSON.stringify(arr))
}

export const getGoodNum = () => {
  const result = localStorage.getItem(CART_KEY)
  return result ? JSON.parse(result) : ''
}

export const setGoodNum = (num) => {
  return localStorage.setItem(CART_KEY, JSON.stringify(num))
}
