import request from '@/util/request'

// 加入商品进入购物车
export const Addcart = (goodsId, goodsNum, goodsSkuId) => {
  return request.post('/cart/add', {
    goodsId,
    goodsNum,
    goodsSkuId
  })
}

// 获取购物车数据
export const getCartList = () => {
  return request.get('/cart/list')
}

// 清空购物车数据
export const ClearCartList = (cartIds) => {
  return request.post('/cart/clear', {
    cartIds
  })
}
