import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store' // 引入 Vuex store

Vue.use(VueRouter)

// 路由配置
const routes = [
  {
    path: '/',
    redirect: '/Home',
    component: () => import('@/views/Layout'),
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/Home')
      },
      {
        path: '/Category',
        name: 'Category',
        component: () => import('@/views/Category')
      },
      {
        path: '/Cart',
        name: 'Cart',
        component: () => import('@/views/Cart'),
        meta: { requiresAuth: true } // 需要鉴权
      },
      {
        path: '/Mine',
        name: 'Mine',
        component: () => import('@/views/Mine'),
        meta: { requiresAuth: true } // 需要鉴权
      }
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/GoodsDetail/:id',
    name: 'GoodsDetail',
    component: () => import('@/views/GoodsDetail')
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('@/views/Order'),
    meta: { requiresAuth: true } // 需要鉴权
  },
  {
    path: '/Pay',
    name: 'Pay',
    component: () => import('@/views/Pay'),
    meta: { requiresAuth: true } // 需要鉴权
  },
  {
    path: '/Search',
    name: 'Search',
    component: () => import('@/views/Search')
  },
  {
    path: '/SearchList',
    name: 'SearchList',
    component: () => import('@/views/Search/list.vue')
  },
  {
    path: '*', // 404 页面兜底
    redirect: '/Home'
  }
]

// 创建 Vue Router 实例
const router = new VueRouter({
  routes
})

// 解决 Vue Router 重复导航错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = store.getters.token || '' // 获取登录状态

  // 如果目标路由需要鉴权
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      // 如果未登录，跳转到登录页面
      if (to.path !== '/Login') {
        return next('/Login') // 跳转到登录页面
      }
      return next() // 已经在登录页面，直接放行
    }
  }

  // 如果已登录但试图访问登录页面
  if (to.path === '/Login' && token) {
    if (from.path !== '/Home') {
      return next('/Home') // 如果不是从首页来，重定向到首页
    }
    return next() // 如果已经在目标路由，直接放行
  }

  // 正常放行
  next()
})

export default router
