import { setInfo, getInfo } from '@/util/stroage'
export default {
  namespaced: true,
  state () {
    return {
      userInfo: getInfo()
    }
  },
  getters: {
  },
  mutations: {
    setUserInfo (state, value) {
      state.userInfo = value
      setInfo(value)
    }
  },
  actions: {
    logout ({ commit, dispatch }) {
      commit('setUserInfo', {})
      // dispatch('cart/ClearCart', null, { root: true })
    }
  }
}
