import { getCartList, ClearCartList } from '@/api/cart'

export default {
  namespaced: true,
  state () {
    return {
      Total: 0,
      List: [],
      cartIds: []
    }
  },
  getters: {
    cartTotal (state) {
      return state.List.reduce((sum, item) => sum + item.goods_num, 0)
    },
    selectPrice (state) {
      return state.List.reduce((sum, item) => sum + item.goods_num * item.goods.goods_price_min, 0)
    }

  },
  mutations: {
    SETTOTAL (state, total) {
      state.Total = total
    },
    SETLIST (state, list) {
      state.List = list.map(item => ({
        ...item,
        isCheck: false
      }))
    },
    addNum (state, index) {
      state.List[index].goods_num++
    },
    subNum (state, index) {
      state.List[index].goods_num--
    },
    getId (state) {
      state.cartIds = []
      state.List.forEach(element => {
        const res = element.id
        state.cartIds.push(res)
        return state.cartIds
      })
    }
  },
  actions: {
    async getCartInfo ({ commit }) {
      const res = await getCartList()
      const total = res.data.cartTotal
      const list = res.data.list
      commit('SETTOTAL', total)
      commit('SETLIST', list)
    },
    async ClearCart ({ commit, state }) {
      commit('getId')
      await ClearCartList(state.cartIds)
    }
  }
}
