import Vue from 'vue'
import Vuex from 'vuex'
import users from '@/store/users'
import cart from '@/store/cart'

Vue.use(Vuex)

export default new Vuex.Store(
  {
    getters: {
      token (state) {
        return state.users.userInfo.token
      }
    },
    modules: {
      users,
      cart
    }
  }
)
