import Vue from 'vue'
import {
  Button,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  NavBar,
  Toast,
  Search,
  Grid,
  GridItem,
  Icon,
  Tab,
  Tabs,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Popup,
  Cell,
  Rate,
  ActionSheet,
  Dialog,
  Badge,
  Checkbox,
  SubmitBar, Sidebar, SidebarItem, TreeSelect
} from 'vant'
Vue.use(Button)
Vue.use(Sidebar)
Vue.use(TreeSelect)
Vue.use(SidebarItem)
Vue.use(SwipeItem)
Vue.use(Swipe)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Search)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(GoodsAction)
Vue.use(GoodsActionIcon)
Vue.use(GoodsActionButton)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(Rate)
Vue.use(ActionSheet)
Vue.use(Dialog)
Vue.use(Badge)
Vue.use(Checkbox)
Vue.use(SubmitBar)
