import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/util/VantUi.js'
import '@/styles/common.less'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.config.errorHandler = (err, vm, info) => {
  if (err.message.includes('缺少必要的参数 token')) {
    console.warn('捕获到未登录的请求:', info)
  } else {
    console.error(err) // 打印其他错误
  }
}

window.addEventListener('unhandledrejection', (event) => {
  if (event.reason.message.includes('缺少必要的参数 token')) {
    console.warn('捕获到未登录的请求:', event.reason)
  }
})
